
import { ObservableQuery } from '@apollo/client';
import { Button, FormControl, FormLabel, makeStyles, MenuItem, NativeSelect, Paper, Select, styled, Typography } from '@material-ui/core';
import { Filter, Filter1, Tune } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { alpha, Card, Divider, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip } from '@mui/material';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PollContext } from '../../common/context/PollContext';
import { UserContext } from '../../common/context/UserContext';
import { CustomSwitch } from '../../common/CustomSwitch';
import EligoSnackBar from '../../common/EligoSnackBar';
import { PollListContainer } from '../../nav-bar/side-nav/side-nav-style/SideNavStyle';
import SideNav from '../../nav-bar/side-nav/SideNav';
import PollSearch from '../poll-list/poll-search/PollSearch';
import '../poll-list/PollList';
import Polls from '../poll-list/Polls';
import { MobileSideNav } from './MobileSideNav';
import './Poll_table.css';
import SetupButton from './SetupButton';
import { Status, Roles, UserRole, TypeOfPoll } from '../../common/GenericCodes';

export const ButtonStyled = styled(Button)(({ theme }) => ({
    boxShadow: '0 5px 18px -5px rgba(50,50,93,0.25),0 3px 16px -8px rgba(0,0,0,0.3)',
    background: `${theme.palette.primary.main} !important`,
    // fontWeight: 'bold !important',
    color: '#fff9f9',
    '&[disabled]': {
        color: '#fff9f9',
        background: `${alpha(theme.palette.primary.main, 0.5)} !important`
    },
    '&:hover': {
        boxShadow: '0 5px 27px -5px rgba(50,50,93,0.25),0 10px 16px -8px rgba(0,0,0,0.3)',
    }
}))

const useStyles = makeStyles((theme) => ({
    spin: {
        animation: "$spin 1s infinite",
    },
    "@keyframes spin": {
        "0%": {
            transform: "translateX(0%)"
        },
        "100%": {
            transform: "translateX(10%)"
        }
    }
}));


const Poll_table = (props) => {

    const { t } = useTranslation();
    const context = useContext(PollContext);
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedList, setSelectedList] = useState(context.pollDetails.selectedList ? context.pollDetails.selectedList : Status.open);
    const [selectedRole, setSelectedRole] = useState(context.pollDetails.selectedRole ? context.pollDetails.selectedRole : Roles.organizer);
    const pollsRef = useRef(); //reference for Polls Component to call list after select search
    const [menuCollapse, setMenuCollapse] = useState(context.pollDetails.menuCollapse ? context.pollDetails.menuCollapse : false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [loading, setLoading] = useState(false);
    const [pollClass, setPollClass] = useState(context.pollDetails.selectedClass ? context.pollDetails.selectedClass : 'All');

    const open = Boolean(anchorEl);
    const id = open ? 'user-options-popover' : undefined;
    const [ascDescending, setAscDescending] = React.useState(false);

    const handleChange = (event, newAlignment) => {
        if (newAlignment != null) {
            setAscDescending(newAlignment);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleRole = (value) => {
        setLoading(true);
        setSelectedRole(value);
        context.setPollDetails(prevState => ({
            ...prevState,
            selectedRole: value,
            selectedList: value === 'voter' ? selectedList === Status.setup ? Status.open : selectedList : selectedList,
        }))
        setInterval(() => {
            setLoading(false);
        }, 1000)
    }

    const handleClassChange = (value) => {
        setLoading(true);
        setPollClass(value);
        context.setPollDetails(prevState => ({
            ...prevState,
            selectedClass: value,
        }))
        setInterval(() => {
            setLoading(false);
        }, 1000)
    }

    const handleClosed = () => {
        setAnchorEl(null);
    }

    const listChangeHandler = (listType, status) => {
        handleClosed()
        setLoading(true);
        setSelectedList(listType);
        context.setPollDetails(prevState => ({
            ...prevState,
            selectedList: listType ? listType : 'open',
            menuCollapse: menuCollapse,
            selectedStatus: status
        }))
        setInterval(() => {
            setLoading(false);
        }, 1000)
    }

    let history = useHistory();

    const loadSetup = () => {
        handleClosed()
        history.push({ pathname: "/polls/poll-details/setup", state: { poll: null, pollType: selectedList } })
    }

    const addPollRound = (type) => {
        history.push({
            pathname: '/polls/setup',
            state: {
                currentPage: 1,
                // roles: props.location.state.roles,
                pollType: selectedList,
                limit: 5,
                type: type
            }
        })
    }

    const handleMenuCollapse = (collapse) => {
        context.setPollDetails(prevState => ({
            ...prevState,
            menuCollapse: collapse
        }))
    }

    const roleOptions = [
        { value: 'organizer', label: 'Organizer' },
        { value: 'voter', label: 'Voter' },
        { value: 'scrutinizer', label: 'Scrutinizer' },
        { value: 'observer', label: 'Observer' },
    ];
    const hasSystemAdmineOrSupportRole = userContext.userSession.session.roles.includes(UserRole.Role_Support) || userContext.userSession.session.roles.includes(UserRole.Role_Admin)

    return (
        <div>
            <Stack>
                {isBrowser && <BrowserView>
                    <SideNav selectedList={selectedList} listChangeHandler={listChangeHandler} selectedRole={selectedRole}
                        menuCollapse={menuCollapse} handleMenuCollapse={handleMenuCollapse} setMenuCollapse={setMenuCollapse}
                        context={context} setPollClass={handleClassChange} pollClass={pollClass} />
                    <PollListContainer iscollapse={menuCollapse}>
                        <Stack direction='row' spacing={2} px={2} pt={2} pb={1} alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                {selectedList === Status.open && <Typography variant='h5'>
                                    {t('Live')} {pollClass === TypeOfPoll.POLL ? t('Polls') : pollClass === TypeOfPoll.SURVEY ? t('Surveys') : t('Polls_Surveys')}
                                </Typography>}
                                {selectedList === Status.scheduled && <Typography variant='h5'>
                                    {t('Upcoming')} {pollClass === TypeOfPoll.POLL ? t('Polls') : pollClass === TypeOfPoll.SURVEY ? t('Surveys') : t('Polls_Surveys')}
                                </Typography>}
                                {selectedList === Status.setup && <Typography variant='h5'>
                                    {t('Under_Setup')}
                                </Typography>}
                                {selectedList === Status.closed && <Typography variant='h5'>
                                    {t('Closed')} {pollClass === TypeOfPoll.POLL ? t('Polls') : pollClass === TypeOfPoll.SURVEY ? t('Surveys') : t('Polls_Surveys')}
                                </Typography>}
                                {selectedList === Status.canceled && <Typography variant='h5'>
                                    {t('Cancelled')} {pollClass === TypeOfPoll.POLL ? t('Polls') : pollClass === TypeOfPoll.SURVEY ? t('Surveys') : t('Polls_Surveys')}
                                </Typography>}
                                {/* {!userContext.userSession.system_admin_of?.includes('e-voting') && selectedRole === 'organizer' &&
                                    <Typography variant='subtitle1' style={{ maxWidth: '100%', color: 'rgb(24 32 123 / 87%)' }} noWrap>
                                        {t('For_Organizer')}
                                    </Typography>}
                                {!userContext.userSession.system_admin_of?.includes('e-voting') && selectedRole === 'voter' &&
                                    <Typography variant='subtitle1' style={{ maxWidth: '100%', color: 'rgb(24 32 123 / 87%)' }} noWrap>
                                        {t('For_Voter')}
                                    </Typography>} */}
                            </Stack>
                            <Stack width={{ xs: '100%', sm: '100%', lg: '40%', md: '30%' }}>
                                <PollSearch pollType={selectedList} role={selectedRole} onSelect={(e, touched) => pollsRef.current.setSelectedPoll(e, touched)}></PollSearch>
                            </Stack>
                            <Stack direction='row' spacing={3} >
                                {isBrowser && !userContext.userSession.system_admin_of?.includes('e-voting') && !hasSystemAdmineOrSupportRole &&
                                    <Fragment>
                                        <Stack direction='row' alignItems='center' spacing={0.8} sx={{
                                            background: '#eee9f9',
                                            borderRadius: '5px',
                                            padding: '0.1rem 0.1rem 0.1rem 0.3rem',
                                            color: '#220776',
                                            border: '1px solid #d0c5f2'
                                        }}>
                                            <Tune className={classes.spin} />
                                            <FormLabel style={{ color: '#220776' }}>{t('Select_Role')}</FormLabel>
                                            <FormControl size='small' style={{
                                                background: '#fff', outline: 'none',
                                                borderColor: '#9ecaed',
                                                boxShadow: '0 0 10px rgb(150 155 240)',
                                                borderRadius: '5px'
                                            }}>
                                                <Select
                                                    id="select-role"
                                                    onChange={(event) => { handleRole(event.target?.value) }}
                                                    value={selectedRole}
                                                    variant='outlined'
                                                >
                                                    {
                                                        roleOptions.map(option => {
                                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                    </Fragment>
                                }
                                <ToggleButtonGroup
                                    color="primary"
                                    value={ascDescending}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                    size='small'
                                >
                                    <Tooltip title={t("Sort_Ascending")} arrow>
                                        <ToggleButton
                                            value={true}
                                            sx={{
                                                fontWeight: ascDescending === true && 'bold',
                                                backgroundColor: ascDescending === true ? 'primary.main' : 'white',
                                                color: ascDescending === true && 'white',
                                                borderRight: '1px solid lightblue',
                                                '&:hover': {
                                                    backgroundColor: ascDescending === true && 'primary.main',
                                                },
                                            }}
                                        >
                                            {t("Asc")}
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title={t("Sort_Descending")} arrow>
                                        <ToggleButton
                                            value={false}
                                            sx={{
                                                fontWeight: ascDescending === false && 'bold',
                                                backgroundColor: ascDescending === false ? 'primary.main' : 'white',
                                                color: ascDescending === false && 'white',
                                                borderLeft: '1px solid lightblue',
                                                '&:hover': {
                                                    backgroundColor: ascDescending === false && 'primary.main',
                                                },
                                            }}
                                        >
                                            {t("Dsc")}
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                                <SetupButton setupPoll={addPollRound} />
                            </Stack>
                        </Stack>
                        <Stack marginTop={0}>
                            {!loading && <Polls handleRole={handleRole} ascDescending={ascDescending} pollType={selectedList} roleType={selectedRole} ref={pollsRef}
                                setMessage={setMessage} pollClass={pollClass}></Polls>}
                        </Stack>
                    </PollListContainer>
                </BrowserView>}
                {!isBrowser &&
                    <MobileView>
                        <Fragment>
                            <Paper style={{ padding: '0.3rem 0.8rem' }}>
                                <Stack direction='row' spacing={1} style={{ width: '100%' }} justifyContent='start' alignItems='center'>
                                    <Stack width={{ xs: '10%', sm: '10%', md: '5%' }}>
                                        <MenuIcon onClick={() => setOpenDrawer(true)} style={{ cursor: 'pointer' }} />
                                    </Stack>
                                    <Stack width={{ xs: '40%', sm: '40%', md: '30%' }}>
                                        <Typography variant='h6' style={{ textTransform: 'capitalize' }}>
                                            {selectedList === Status.open && t('Polls_Open')}
                                            {selectedList === Status.scheduled && t('Polls_Upcoming')}
                                            {selectedList === Status.closed && t('Polls_Closed')}
                                            {selectedList === Status.canceled && t('Polls_Canceled')}
                                        </Typography>
                                    </Stack>
                                    <Stack width={{ xs: '50%', sm: '50%', md: '65%' }}>
                                        <PollSearch pollType={selectedList} role={selectedRole}
                                            onSelect={(e, touched) => pollsRef.current.setSelectedPoll(e, touched)}
                                        />
                                    </Stack>
                                </Stack>
                            </Paper>
                            {openDrawer && <Toolbar sx={{ height: '45px' }}>
                                <MobileSideNav
                                    openDrawer={openDrawer}
                                    setOpenDrawer={setOpenDrawer}
                                    selectedList={selectedList}
                                    listChangeHandler={listChangeHandler}
                                /></Toolbar>}
                            {!loading && <Polls pollType={selectedList} roleType={'voter'} ref={pollsRef}
                                pollClass={pollClass}></Polls>}
                        </Fragment>
                    </MobileView>
                }
            </Stack>
            {
                props.location.state != null && props.location.state != undefined ?
                    <EligoSnackBar show={props.location.state.show} message={props.location.state.message}
                        severity={props.location.state.severity}></EligoSnackBar> : null
            }

            {message.show && <EligoSnackBar show={message.show} message={message.message}
                severity={message.severity}></EligoSnackBar>}
        </div >
    )
}

export default React.memo(Poll_table)