import React                        from 'react';
import { Box }                      from '@mui/system';
import { TablePagination }          from '@mui/material';

const VotingPagination = ({count, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, children}) => {
    console.log('page: ', page, rowsPerPage);

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" padding="5px"
                sx={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'}}>
            <Box sx={{width: '50%'}}>
                {children}
            </Box>

            <TablePagination
                rowsPerPageOptions={[5, 10, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default VotingPagination;