import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Pagination from '@material-ui/lab/Pagination';
import { Box, Grid, Stack } from '@mui/material';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar';
import { UserContext } from '../../common/context/UserContext';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import PollRoundList from '../poll-round/poll-round-list/PollRoundList';
import PollRow from './PollRow';
import VotingPagination from '../../common/VotingPagination';

const Row = (props) => {
    let backgroundMap = new Map([
        ['open', 'linear-gradient(to bottom right, #e7f3c7 0%, #ffffff 100%)'],
        ['closed', 'linear-gradient(to bottom right, #ffd8d8 0%, #ffffff 100%)'],
        ['new', 'linear-gradient(to bottom right, #ffffe5 0%, #ffffff 100%)'],
        ['canceled', 'linear-gradient(to right bottom, #c7c7c7 0%, rgb(255, 255, 255) 100%)'],
        ['scheduled', 'linear-gradient(to right bottom, #fbe0d1 0%, rgb(255, 255, 255) 100%)'],
    ])
    let borderMap = new Map([
        ['open', '2px solid #b6d852'],
        ['closed', '2px solid #ff6262'],
        ['new', '2px solid #ffff7f'],
        ['deleted', 'transparent'],
        ['scheduled', '2px solid #ea905d'],
    ])
    if (props.data.listPoll.polls == null || props.data.listPoll.polls?.length == 0) {
        return <Typography className="polls-not-found"><WarningIcon style={{ fontSize: '45px', color: 'orange' }} /><br />{props.t('No_Polls_Are_Available')}.</Typography>
    }
    if (props.data.listPoll.polls != null) {
        return props.data.listPoll.polls.map((poll, index) => {
            const setRoles = (roles) => {
                let roleList = [];
                roles.map(e => {
                    roleList.push(e)
                })
                return roleList;
            }
            let pollDetails = {
                event_id: poll.event_id,
                category_cd: poll.category_cd,
                title: JSON.parse((poll.title).replaceAll("'", "\"")),
                purpose: JSON.parse((poll.purpose).replaceAll("'", "\"")),
                anonymous_voting: poll.anonymous_voting,
                geo_fencing: poll.geo_fencing,
                geo_tagging: poll.geo_tagging,
                question_shuffle: poll.question_shuffle,
                sequence: poll.sequence,
                show_results_to_voters: poll.show_results_to_voters,
                website_redirect: poll.website_redirect,
                weighted_votes: poll.weighted_votes,
                disclose_voter_in_result: poll.disclose_voter_in_result,
                start_manually: poll.start_manually,
                startTime: poll.startTime,
                endTime: poll.endTime,
                roles: setRoles(poll.roles),
                status: poll.status,
                category_description: poll.category_description
            }
            return <PollRow poll={pollDetails} key={poll.event_id} pollType={props.pollType} page={props.page} itemsPerPage={props.itemsPerPage}
                bgStyle={backgroundMap.get(props.pollType)} borderStyle={borderMap.get(props.pollType)}></PollRow>
        })
    }

    return <div></div>;

}

const Polls = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const context = useContext(UserContext);
    const [itemsPerPage] = useState(5);
    const [pollList, setPollList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pollError, setPollError] = useState('');
    const [count, setCount] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [tableContainerPosition, setTableContainerPosition] = useState(200);
    const headerRef = useRef();
    const footerRef = useRef();

    const getPollList = () => {
        setLoading(true)
        AxiosEvotingInterceptor.get(`poll/${props.pollType.toUpperCase()}/${props.roleType.toUpperCase()}?${props.pollClass !== 'All' ? `pollType=${props.pollClass.toLowerCase()}&` : ''}page=${page + 1}&limit=${rowsPerPage}&asc=${props.ascDescending}`).then(response => {
            if (response) {
                setPollError('');
                setCount(response.count);
                setPollList(response.polls);
                if (response.count === itemsPerPage) {
                    setPage(0)
                }
                if (isBrowser) {
                    let roles = ['organizer', 'voter']
                    // onLoad toggling between organizer and voter
                    if (context.userSession?.session?.roleCounter < roles.length && response.count === 0) {
                        let role = props.roleType === 'organizer' ? 'voter' : 'organizer'
                        props.handleRole(role)
                        //encounter where organizer || voter list is empty
                        context.setUserSession(prevState => ({
                            ...prevState,
                            session: {
                                ...prevState.session,
                                roleCounter: context.userSession?.session?.roleCounter + 1,
                            }
                        }))
                    }
                    else if (response.count > 0 && context.userSession?.session?.roleCounter < roles.length) {
                        context.setUserSession(prevState => ({
                            ...prevState,
                            session: {
                                ...prevState.session,
                                roleCounter: 2,
                            }
                        }))
                    }
                }
            }
            setLoading(false)
        }).catch(error => {
            if (error.message) {
                setPollError(error.message);
            } else {
                setPollError(t('Try_Again'));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getPollList();
    }, [page, rowsPerPage, props.ascDescending]);

    const setSelectedPoll = (selectedPoll, touched) => {
        if ((selectedPoll === '' || selectedPoll === null) && touched) {
            setShowPagination(false)
            getPollList()
        } else {
            setTimeout(function () {
                if (selectedPoll != '' && selectedPoll != null) {
                    setShowPagination(true)
                    setPollList([selectedPoll]);
                }
            }, 200);
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            setSelectedPoll(selectedPoll, touched) {
                setSelectedPoll(selectedPoll, touched)
            }
        }),
    )

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (headerRef.current && footerRef.current) {
            setTableContainerPosition(headerRef.current.getBoundingClientRect().bottom + footerRef.current.getBoundingClientRect().height);
        }
    }, []);

    return (
        <>
            {loading && <Box sx={{ marginTop: '20rem' }}><EligoBackdrop show={loading} /></Box>}
            {!loading && pollList && 
           <Box
        //    sx={{
        //        maxHeight: 'calc(100vh - 150px)', // Adjust height based on your header and other content
        //        overflowY: 'auto',
            //    padding: '0 15px'
        //    }}
           ref={headerRef}
       >
                <PollRoundList polls={pollList} page={page} itemsPerPage={itemsPerPage} pollType={props.pollType} getPollList={getPollList}
                    role={props.roleType} setMessage={props.setMessage} setPage={setPage} pollClass={props.pollClass}/> 
            </Box>}
            {count > itemsPerPage && !showPagination &&
                <Grid item xs={12} ref={footerRef} sx={{ padding: '0px 15px', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <VotingPagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            }
            {pollError !== '' && pollError !== undefined ?
                <EligoSnackBar show={true} message={pollError} severity="error" reset={() => setPollError(null)} /> : null}
        </>
    )
})

export default React.memo(Polls);